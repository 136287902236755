var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lever-deal"},[_c('div',{staticClass:"deal"},[_c('div',{staticClass:"deal-center"},[_c('div',{staticClass:"mb-24"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"down-menu",on:{"click":function($event){_vm.show = true}}},[_c('span',{staticClass:"coin-name"},[_vm._v(_vm._s(_vm.showProductName())+"/USDT")]),_c('div',{staticClass:"svg-icon mt-1 icon-ic_down"})]),_c('div',{staticClass:"tradata-right"},[_c('ul',{staticClass:"header-ul",style:({
                  color:
                    _vm.productNowInfo[_vm.activeIndex].BasicPrice -
                      _vm.productNowInfo[_vm.activeIndex].Price >
                    0
                      ? _vm.basicConfig.FallColour
                      : _vm.basicConfig.RiseColour
                })},[_c('li',{staticClass:"family-d"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Price)+" ")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pcrapidly').dd))]),_c('div',{staticClass:"number red"},[_vm._v("-6.48%")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').gao))]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].High)+" ")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').di))]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Low)+" ")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').liang))]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Amount)+" ")])])])])]),_c('div',{staticClass:"icon"})]),_c('div',{staticClass:"data-show"},[_c('div',{staticClass:"k-chart"},[_c('TradingView',{ref:"tra",attrs:{"TvCode":_vm.select_tv_code,"Height":"540px"}})],1)])]),_c('div',{staticClass:"bottom-tab"},[_c('Trust',{ref:"trust",attrs:{"productId":_vm.productNowInfo[_vm.activeIndex].Id}})],1)]),_c('Test',{on:{"loadData":_vm.loadData}})],1),_c('van-popup',{style:({ width: '262px', height: '100vh' }),attrs:{"position":"left"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('PopupInfo',{attrs:{"productNowInfo":_vm.productNowInfo},on:{"change":_vm.changeProduct}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }