<template>
  <div class="bottom-history">
    <div class="title">
      <div class="left">
        <span class="title1">{{ $t('trade').dqwt }}</span>
      </div>
      <div
        class="right"
        @click="
          $router.push({
            name: 'LEntrust',
            query: {
              id: productId
            }
          })
        "
      >
        <img src="@/assets/new/icon3.png" alt="" />{{ $t('trade').cc }}
      </div>
    </div>
    <div class="head">
      <span>{{ $t('trade').leixing }}</span>
      <span>{{ $t('trade').shijian }}</span>
      <span>{{ $t('trade').jiage }}</span>
      <span>{{ $t('trade').jiaoyie }}</span>
      <span>{{ $t('trade').caozuo }}</span>
    </div>
    <NoData v-if="list.length === 0" />
    <van-list
      class="list-box"
      ref="list"
      v-else
      v-model="loading"
      :finished="finished"
      loading-text=""
      finished-text=""
      @load="getList"
    >
      <div class="info" v-for="(item, index) in list" :key="index">
        <div>{{ $t('trade')[item.BuyDirection] }}</div>
        <div>{{ item.CreateTime | getLocalTime }}</div>
        <div>{{ item.StartPrice }}</div>
        <div>{{ item.TransactionAmount }}</div>
        <div>
          <span class="btn" @click="handleCancle(item.OrderNo)">{{
            $t('public').cancel
          }}</span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import NoData from '@/components/noData'
import productApi from '@/api/product'
export default {
  props: {
    productId: {
      type: Number,
      default: 0
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      list: [],
      activeSelect: 0,
      loading: false,
      finished: false,
      form: {
        page: 1,
        size: 10,
        productId: null
      }
    }
  },
  watch: {
    productId(Val) {
      this.form.productId = Val
    }
  },
  mounted() {
    setTimeout(() => {
      this.form.productId = this.productId
      this.getList()
    }, 200)
  },
  methods: {
    async handleCancle(OrderNo) {
      await productApi.cancelContractOrder(OrderNo)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.reset()
      }, 500)
    },
    reset() {
      this.form.page = 1
      this.finished = false
      this.list = []
      this.getList()
    },
    async getList() {
      const res = await productApi.ContractOrderRecord(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  ::v-deep .van-picker {
    background: #020406;
    .van-picker__toolbar {
      background: #010203;
    }
  }
  ::v-deep .van-picker-column__item {
    color: #fff !important;
  }
  ::v-deep .van-picker-column__item--selected {
    font-size: 20px;
    font-weight: bold;
  }
  ::v-deep .van-picker__mask {
    background: rgba(0, 0, 0, 0.5);
  }
}
.bottom-history {
  background: #17181e;
  margin-top: 10px;
  height: calc(100vh - 610px);
  .title {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    .left {
      .title1 {
        font-size: 18px;
        color: #fff;
        margin-right: 8px;
      }
      img {
        width: 10px;
        height: 10px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      font-size: 14px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  .head {
    display: flex;
    span {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
    }
  }
  .list-box {
    height: calc(100% - 66px);
    overflow: scroll;
    .info {
      display: flex;
      align-items: center;
      height: 30px;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn {
        border: 1px solid #d74e5a;
        padding: 1px 3px;
        display: inline-block;
        color: #d74e5a;
      }
    }
  }
}
</style>
